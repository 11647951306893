<script lang="ts">
  export let message = "unknown error";
</script>

<span>
  {message}
</span>

<style>
  span {
    color: red;
    font-weight: bold;
  }
</style>
